// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';

const Remark = ({ number, text }) => {
  return (
    <div className={Style.remark}>
      <p className={Style.num}>{number}</p>
      <p className={Style.text}>{text}</p>
    </div>
  );
};

const RemarkList = ({ remarks }) =>
  (remarks && (
    <div className={Style.remarkList}>
      {remarks.map((rm, index) => (
        <Remark key={index} {...rm} />
      ))}
    </div>
  )) ||
  null;

const SectionRemark = ({ title, remarks }) => {
  return (
    <section className="container">
      <p className={Style.title}>{title}</p>
      <RemarkList remarks={remarks} />
    </section>
  );
};

export default SectionRemark;
