import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroApp from '../components/HeroApp';
import SectionAppPerk from '../components/SectionAppPerk';
import SectionRemark from '../components/SectionRemark';
import polylangTranslationPathHelper from '@utils/polylangTranslationPathHelper';

const App = ({ data, location }) => {
  const {
    wordpressPage: page,
    wordpressAcfPages: {
      acf: { app_hero: hero, app_perks: perks, app_remarks: remarks },
    },
  } = data;

  let translations = polylangTranslationPathHelper(page.polylang_translations);

  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      pageLang={page.polylang_current_lang}
      translations={translations}
      useTranslationsPath={true}
    >
      <SEO
        {...page}
        pageLang={page.polylang_current_lang}
        translations={translations}
      />
      <HeroApp {...hero} />
      {perks.map((perk, idx) => (
        <SectionAppPerk key={idx} {...perk} />
      ))}
      <SectionRemark {...remarks} />
    </Layout>
  );
};

export const query = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      content
      polylang_current_lang
      polylang_translations {
        path
        polylang_current_lang
      }
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        app_hero {
          appleStore
          appleStoreLink
          googlePlay
          googlePlayLink
          subtitle
          title
          backgroundImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  src
                }
              }
            }
          }
        }
        app_perks {
          description
          reverse
          subtitle
          svg
          title
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  src
                }
              }
            }
          }
        }
        app_remarks {
          remarks {
            number
            text
          }
          title
        }
      }
    }
  }
`;

export default App;
