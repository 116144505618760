// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import ScrollButton from '../ScrollButton';
import Img from '@components/ImageWrapper';
import { translate as t } from '../../utils/translate';

const HeroApp = ({
  backgroundImage,
  title,
  subtitle,
  appleStoreLink,
  googlePlayLink,
  appleStore,
  googlePlay,
}) => {
  const TEXTS = {
    AVAILABLE_IN: t('availableIn'),
    LEARN_MORE: t('learnMore'),
  };

  return (
    <section className={Style.heroApp}>
      <div className={[Style.background, Style.blur].join(' ')} />
      <Img
        src={backgroundImage}
        style={{ position: 'absolute', overflow: 'inherit' }}
        className={[Style.background, Style.image].join(' ')}
      />
      <div className={[Style.background, Style.overlay].join(' ')} />
      <div className={Style.heroContent}>
        <p className={Style.title}>{title}</p>
        <p
          className={Style.subtitle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></p>
        <div className={Style.appContainer}>
          <p className={Style.appTitle}>{TEXTS.AVAILABLE_IN}</p>
          <div className={Style.apps}>
            <a className={Style.appLink} href={appleStoreLink} target="_blank">
              <span
                className={Style.appIconContainer}
                dangerouslySetInnerHTML={{ __html: appleStore }}
              />
            </a>
            <a className={Style.appLink} href={googlePlayLink} target="_blank">
              <span
                className={Style.appIconContainer}
                dangerouslySetInnerHTML={{ __html: googlePlay }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className={Style.scrollButtonContainer}>
        <ScrollButton
          text={TEXTS.LEARN_MORE}
          refID="heroAboutUsAnchor"
          style={{ boxShadow: '10px 4px 40px #3CCCB72B' }}
        />
      </div>
    </section>
  );
};

export default HeroApp;
