// TODO: REFACTORING
import React from 'react';
import Style from './style.module.css';
import Img from '@components/ImageWrapper';

const SectionAppPerk = ({
  title,
  subtitle,
  description,
  image,
  svg,
  reverse,
}) => {
  const { imageContainer, reverseOrder } = Style;

  return (
    <section
      className={[Style.perk, 'container', reverse ? reverseOrder : ''].join(
        ' '
      )}
    >
      <div className={Style.textContainer}>
        <p className={Style.title}>{title}</p>
        <p className={Style.subtitle}>{subtitle}</p>
        <p className={Style.description}>{description}</p>
      </div>
      {svg ? (
        <div
          className={imageContainer}
          dangerouslySetInnerHTML={{ __html: svg }}
        ></div>
      ) : (
        <div className={imageContainer}>
          <Img
            src={image}
            className={[Style.image, reverse ? reverseOrder : ''].join(' ')}
            imgStyle={{ position: 'static' }}
          />
        </div>
      )}
    </section>
  );
};

export default SectionAppPerk;
